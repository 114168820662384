<template>
  <div>
    <div class="tab" :class="navBarFixed ? 'navBarFixed' : ''">
      <img
        @click="goback"
        src="../../../../static/common/logoBlack.png"
        alt=""
      />
    </div>
    <div class="step_1">
      <div @click="getActivity" class="btn"></div>
      <div class="step_2">
        <div class="rules" @click="rulesModel = true">活动规则</div>
        <div class="box" @click="getActivity"></div>
        <div class="text">首入$100即送，最高兑换$5/手</div>
        <img @click="getActivity" src="/activity/czzj_6357/wap/s3.png" alt="" />
      </div>
      <div class="step_3">
        <div class="btn">
          <div @click="getLive800"></div>
          <div @click="getActivity"></div>
        </div>
      </div>
      <div class="step_4">
        <div @click="getActivity"></div>
      </div>
      <div class="step_5">
        <img class="box" src="/activity/czzj_6357/wap/s6.png" alt="" />
        <div class="btnBox">
          <div class="item">
            <span>咨询客服，获取最新版官方APP</span>
            <img
              @click="getLive800"
              src="/activity/czzj_6357/wap/s7.png"
              alt=""
            />
          </div>
          <div class="item">
            <span>盈利的快乐 这个春天你要知道</span>
            <img
              @click="getActivity"
              src="/activity/czzj_6357/wap/s8.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="mask" v-if="rulesModel">
      <div class="popup">
        <div class="closeBox">
          <img
            class="guanbi1"
            @click="rulesModel = false"
            src="/activity/czzj_6357/wap/s9.png"
            alt=""
          />
        </div>
        <ul class="dialogContent">
          <li>
            <span>活动对象：</span>
            活动期间，在本平台开立真实账户，并首次入金的客户
          </li>
          <li>
            <span>活动时间：</span>
            即日起至2023年03月31日23:59
          </li>
          <li>
            <span>活动规则：</span><br />
            首次充值，充多少送多少美元赠金，奖励上不封顶；<br />
            72小时内总入金≥500美元，可额外获得100美元现金；<br />
            首入金奖励如下：
            <table>
              <tr>
                <th>层级</th>
                <th>首入条件<br/>(72h内)</th>
                <th>美元赠金<br/>(100%赠送)</th>
                <th>美元现金<br/>(限时送)</th>
                <th>美元现金提现条件<br/>(30天内)</th>
                <th>总获赠金额</th>
              </tr>
              <tr>
                <th>1</th>
                <th>总入金＜$500</th>
                <th>＜$500</th>
                <th>无</th>
                <th>无</th>
                <th>＜500美元赠金</th>
              </tr>
              <tr>
                <th>2</th>
                <th>总入金≥$500</th>
                <th>≥$500</th>
                <th>100</th>
                <th>≥10手</th>
                <th>≥500美元赠金<br/>+100美元现金</th>
              </tr>
              <tr>
                <th></th>
                <th></th>
                <th>充多少 送多少</th>
                <th>100</th>
                <th></th>
                <th></th>
              </tr>
            </table>
          </li>
          <li>
            <span>美元赠金：</span><br />
            1、活动期间内，首次入金可获得等额的美元赠金（即赠金点）回馈；<br />
            2、 首入金有效期为第一笔入金后的72h内，赠送金额将按照72h后最终数额计算；<br />
            3、用户每交易一手伦敦金/伦敦银，返还相对应的赠金金额；该笔资金可交易可提现，用户可登录App-我的-赠金奖励中查看（未满一手按照实际交易手数占一手的比例计算）<br />
            4、美元赠金按交易手数返还，当月交易越多，每手返还越高！<br />
            <span style="color:#00c1eb;font-weight: 500;">更多活动详情，请下载App-我的-赠金奖励中查看，或咨询客服。</span>
          </li>
          <li>
            <span>美元现金：</span><br />
            1、美元现金直接入账，可交易，可提现；<br />
            2、用户在30天内完成≥10手交易即可提现，品种限伦敦金/伦敦银；<br />
            3、30天内未完成手数要求，活动到期时，系统仅扣回美元现金金额。<br />
            举例说明：A女士，入金520美元，获得100美元现金，账户实际到账金额为520+100=620美元，第15天，A女士完成10手交易，顺利提取100美元现金。<br />
          </li>
          <li>
            <span>注意事项：</span><br />
            1. 客户参与活动需在本公司开立真实交易账户,参与该活动的新用户，为保证资金安全，在初始L1层级，账户强平比例上调至50%，升级后自动恢复为20%；<br />
            2. 交易手数计算：开仓一手平仓一手，如此来回算一手；<br />
            3. 本活动本公司新客户仅有一次机会参与，不可重复领取；<br />
            4. 凡推广商及其名下客户不参与本活动；<br />
            5.
            本公司保留随时修订、暂停、终止本活动及任何相关规则条款之权利及其解释权。
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "czzj_6357_wap",
  data() {
    return {
      rulesModel: false,
      navBarFixed: false,
    };
  },
  created() {
    this.$emit("controlShow", false);
    if (document.body.clientWidth > 500) {
      this.$router.push({
        name: "czzj_6357",
      });
    }
  },
  methods: {
    // 返回上级页面
    goback() {
      window.location.href = window.location.origin;
    },
    getActivity() {
      window.open("https://www.rlcvipltd.net/uc/signUp/real");
    },
    getLive800() {
      // window.open(
      //   "https://chat32.live800.com/live800/chatClient/chatbox.jsp?companyID=215184&configID=35519&jid=5377035377"
      // );
      window.open(this.$parent.live800Url)
    },
  },
};
</script>

<style lang="less" scoped>
.tab {
  width: 7.5rem;
  height: 50px;
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding-left: 10px;
  img {
    width: 120px;
  }
}
.step_1 {
  width: 100%;
  height: 63rem;
  box-sizing: border-box;
  padding-top: 4rem;
  background: url("/activity/czzj_6357/wap/s1.png") no-repeat;
  background-size: 100% 100%;
  .btn {
    width: 60%;
    height: 1.3rem;
    cursor: pointer;
    margin: 0 auto;
  }
}
.step_2 {
  width: 94%;
  height: 8.5rem;
  background: url("/activity/czzj_6357/wap/s2.png") no-repeat center;
  background-size: 100% 100%;
  margin: 0 auto;
  margin-top: 8.5rem;
  box-sizing: border-box;
  padding-top: 1.3rem;
  text-align: center;
  .rules {
    color: #0d7260;
    font-size: 0.38rem;
    cursor: pointer;
    text-align: center;
    font-weight: 600;
  }
  .box {
    width: 100%;
    height: 1rem;
    margin-top: 3rem;
  }
  .text {
    color: #0d7260;
    font-size: 0.38rem;
    font-weight: 600;
    text-align: center;
    margin-top: 0.5rem;
  }
  img {
    height: 1.5rem;
    margin-top: 0.15rem;
  }
}
.step_3 {
  width: 94%;
  height: 16rem;
  background: url("/activity/czzj_6357/wap/s4.png?v=0227") no-repeat center;
  background-size: 100% 100%;
  margin: 0 auto;
  margin-top: 0.55rem;
  box-sizing: border-box;
  padding-top: 16.5rem;
  text-align: center;
  .btn {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    div {
      width: 40%;
      height: 1.5rem;
    }
  }
}
.step_4 {
  width: 94%;
  height: 8.4rem;
  background: url("/activity/czzj_6357/wap/s5.png") no-repeat center;
  background-size: 100% 100%;
  margin: 0 auto;
  margin-top: 0.55rem;
  box-sizing: border-box;
  padding-top: 5.5rem;
  div {
    float: right;
    width: 60%;
    height: 1.5rem;
  }
}
.step_5 {
  width: 94%;
  margin: 0 auto;
  margin-top: 0.55rem;
  .box {
    width: 100%;
  }
  .btnBox {
    width: 100%;
    .item {
      height: 0.8rem;
      line-height: 0.8rem;
      margin-top: 0.4rem;
      span {
        float: left;
        color: #fff;
        font-size: 0.3rem;
        font-weight: 600;
      }
      img {
        height: 0.8rem;
        float: right;
      }
    }
  }
}
.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
}
.popup {
  width: 95%;
  height: 12rem;
  border-radius: 0.3rem;
  background: #e9f7f7;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%;-50%);
  box-sizing: border-box;
  padding: 10px;
  box-shadow: 0px 0px 95px 0px rgba(43, 53, 105, 0.1);
  overflow-y: scroll;
  z-index: 99999999999999;
  .closeBox {
    height: 0.5rem;
    .guanbi1 {
      float: right;
      height: 0.5rem;
      cursor: pointer;
      margin-right: 0.1rem;
    }
  }
  .dialogContent {
    box-sizing: border-box;
    padding: 0.2rem 0.1rem;
    li {
      font-size: 0.3rem;
      color: #599b8f;
      line-height: 0.57rem;
      span {
        font-weight: 600;
        color: #0d7260;
      }
      img {
        width: 100%;
        height: auto;
        margin: 20px 0;
      }
      table {
        border: 1px solid #0d7260;
        border-radius: 0.1rem;
        tr {
          th {
            text-align: center;
            border-bottom: 1px solid #599b8f;
            border-right: 1px solid #599b8f;
            padding: 0.05rem;
            line-height: 0.4rem;
            font-size: 0.2rem;
            font-weight: 500;
          }
          th:last-child {
            border-right: 1px solid transparent;
          }
          &:last-child {
            th {
              border-bottom: none;
            }
          }
        }
        tr:nth-child(1) {
          th {
            font-weight: 600;
          }
        }
      }
    }
  }
}
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background-color: none;
}

::-webkit-scrollbar-thumb {
  background-color: none;
}

::-webkit-scrollbar-thumb:hover {
  background-color: none;
}

::-webkit-scrollbar-thumb:active {
  background-color: none;
}
</style>